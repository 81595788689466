.container {
    min-height: 550px;
    background: linear-gradient(to bottom, rgba(0,0,0,0) 10%, rgba(0,0,0,1)), url("../../assets/vault-background.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    padding: 20px;
    border-radius: 20px;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: none;
}

.vault-title {
    font-family: 'Gotham', sans-serif;
    font-weight: 500;
    font-size: 30px;
    white-space: nowrap;
    text-align: center;
}

@keyframes rotate {
    from {
        transform: rotateX(50deg) rotateZ(0deg) translateZ(-0.5rem);
   }
    50% {
        transform: rotateX(50deg) rotateZ(180deg) translateZ(0.5rem);
   }
    to {
        transform: rotateX(50deg) rotateZ(360deg) translateZ(-0.5rem);
   }
}
.wrapper {
    position: absolute;
    transform-style: preserve-3d;
    width: 6rem;
    height: 6rem;
    margin-top: -145px;
    transform-origin: 3rem 3rem;
    transform: rotateX(50deg) rotateZ(45deg);
    animation: rotate 12s linear infinite;
}
/* .wrapper:hover {
    animation-play-state: paused;
} */
.pyramid {
    position: absolute;
    perspective: 500px;
    transform-style: preserve-3d;
}
.pyramid.inverse {
    transform: translateZ(-1.1428571429rem) rotateY(180deg);
}
.square {
    width: 6rem;
    height: 6rem;
    background: #662fcc;
    transform-style: preserve-3d;
}
.triangle {
    position: absolute;
    width: 8rem;
    height: 8rem;
}
.triangle:nth-child(1) {
    width: 6rem;
    top: -33%;
    background: #a589dd;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    transform-origin: 50% 100%;
    transform: rotateX(-68deg);
}
.triangle:nth-child(2) {
    width: 6rem;
    background: #a589dd;
    clip-path: polygon(50% 100%, 0 0, 100% 0);
    transform-origin: 50% 0%;
    transform: rotateX(68deg);
}
.triangle:nth-child(3) {
    height: 6rem;
    left: -33%;
    background: rgb(128, 90, 138);
    transform-origin: 100% 50%;
    clip-path: polygon(100% 100%, 0 50%, 100% 0);
    transform: rotateY(68deg);
}
.triangle:nth-child(4) {
    height: 6rem;
    background: rgb(128, 90, 138);
    transform-origin: 0% 50%;
    clip-path: polygon(0 100%, 100% 50%, 0 0);
    transform: rotateY(-68deg);
}

.countdown {
    position: relative;
    margin-top: -140px;
}

.vault-footer {
    grid-template-columns: 1fr 1fr 1fr;
    display: grid;
}

@media (max-width: 2000px) {
    .claim-btn {
        float: right !important;    
    }
    .vault-footer {
        margin-bottom: 25px !important;
    }
}

@media (max-width: 761px) {
    .claim-btn {
        float: none !important;
    }
    .vault-button-ctr {
        text-align: center !important;
    }

    .vault-footer {
        grid-template-columns: 1fr;
        margin-bottom: -150px !important;
    }

    .container {
        min-height: 650px !important;
    }
}

@media (max-width: 573px) {
    .vault-footer {
        grid-template-columns: 1fr;
        margin-bottom: -200px !important;
    }
}