#migrate-bg {
    background-image: url("../assets/migrate-bg.png");
    width: 100vw;
    height: 100vh;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    object-fit: cover;
    font-family: 'Gotham', sans-serif;
    color: white;
    position: absolute;
    position: fixed;
    overflow-y: auto;
}

.migrate-center {
    width: 50%;
    margin: 0 auto;
    width: 1200px;
    border-radius: 15px;
    margin-bottom: 100px;
}

.migrate-details {
    margin-top: 100px;
    width: 800px;
    margin-left: 200px;
    background: rgba(0,0,0,0.8);
    border-radius: 30px;
    padding: 10px;
    box-shadow: rgba(206, 2, 172, 0.993) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
}

.migrate-btn {
    background: #5E5DF0 !important;
    border-radius: 999px !important;
    font-size: 16px !important;
    line-height: 24px !important;
    padding: 8px 200px !important;
    border: 0 !important;
    width: 80% !important;
    margin-left: 10%;
    margin-top: 30px;
    margin-bottom: 25px;
  }
  
  .migrate-btn:hover {
      background: #9090f3 !important;
  }