#option-1-bg {
    background-image: url("../assets/yellow_background.png");
    width: 100vw;
    height: 100vh;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    object-fit: cover;
    font-family: 'Gotham', sans-serif;
    color: white;
    position: absolute;
    position: fixed;
    overflow-y: auto;
}

.option-2-swap-box {
    margin-top: 50px;
    width: 700px;
    margin-left: 150px;
    background: rgba(0,0,0,0.8);
    height: 580px;
    border-radius: 70px;
    box-shadow: rgba(206, 2, 172, 0.993) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
    padding: 30px;
} 

.option-center {
    width: 50%;
    margin: 0 auto;
    width: 1000px;
    height: 900px;
    border-radius: 15px;
    margin-bottom: 150px;
}

.option-1-swap-box {
    margin-top: 100px;
    width: 700px;
    margin-left: 150px;
    background: rgba(0,0,0,0.8);
    height: 525px;
    border-radius: 70px;
    box-shadow: rgba(206, 2, 172, 0.993) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
    padding: 30px;
}

.presale-dai-box {
    height: 130px;
    background: rgb(0, 0, 0, 0.9);
    box-shadow: rgba(204, 140, 0, 0.993) 0px 1px 2px, rgb(221, 218, 47) 0px 0px 0px 2px;
    border-radius: 30px;
}

.presale-dai-inner {
    width: 90%;
    margin-left: 5%;
    height: 90%;
    margin-top: 5%;
}

.dai-input {
    border: 0 !important;
    outline: 0 !important;
    -webkit-appearance: none;
    color: white;
    outline-style: none;
    width: 75%;
    height: 30%;
    margin-top: 20px;
    background: transparent;
    float: left;
}

.dai-input:focus {
    outline-style: none !important;
    box-shadow: none  !important;
    border-color: transparent !important;
    background: transparent;
    color: white !important;
}

input[type="number"] {
    font-size: 40px !important;
}

.presale-dai-bottom {
    margin-top: 20px;
    display: inline-block;
    width: 100%;
}

.dai-input-caption {
    width: 20%;
    float: left;
}

.dai-input-balance {
    float: right;
    margin-right: 30px;
}

.presale-output-box {
    margin-top: 20px;
    height: 130px;
    background: rgb(0, 0, 0, 0.9);
    box-shadow: rgba(163, 0, 204, 0.993) 0px 1px 2px, rgb(47, 151, 221) 0px 0px 0px 2px;
    border-radius: 30px;
}

.presale-approve {
  background: #5E5DF0 !important;
  border-radius: 999px !important;
  font-size: 16px !important;
  line-height: 24px !important;
  padding: 8px 200px !important;
  border: 0 !important;
  width: 80% !important;
  margin-left: 10%;
  margin-top: 30px;
}

.presale-approve:hover {
    background: #9090f3 !important;
}

.add-pETHS-to-wallet {
    margin-top: 20px;
    color: #7E84FF;
    cursor: pointer;
}

.add-pETHS-to-wallet:hover {
    text-decoration: underline;
}

.approve-spinner {
    animation: rotate-spinner 2s linear infinite;
    position: absolute;
    z-index: 2;
    width: 30px;
    height: 30px;
    margin-left: 300px;
    margin-top: -35px;
    display: none;
}
.approve-spinner .path {
    stroke: #93bfec;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate-spinner {
    100% {
        transform: rotate(360deg);
   }
}
@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
   }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
   }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
   }
}