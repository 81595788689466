#lottery-bg {
    background-image: url("../assets/lottery-1.png");
    width: 100vw;
    height: 100vh;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    object-fit: cover;
    font-family: 'Gotham', sans-serif;
    color: white;
    position: absolute;
    position: fixed;
    overflow-y: auto;
}

.lottery-spinner {
    animation: rotate-spinner 2s linear infinite;
    z-index: 100;
    width: 30px;
    height: 30px;
    position: absolute;
    /* display: none; */
}

.lottery-spinner .path {
    stroke: #ffffff;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate-spinner {
    100% {
        transform: rotate(360deg);
   }
}