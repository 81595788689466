#bg {
    /* background-image: linear-gradient(315deg, #32303d 0%, #2f2f35 74%); */
    background-image: url("../assets/dark_amethyst.png");
    width: 100%;
    height: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    font-family: 'Gotham', sans-serif;
}

.content {
    margin-left: 35px;
    margin-right: 35px;
    color: #E5DFD8;
}

.header {
    font-family: 'Gotham', sans-serif;
    font-weight: 500;
    font-size: 30px;
}

.cardBox {
    position: relative;
    width: 100%;
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    color: #E5DFD8;
}

.cardBox .card {
    position: relative;
    background: #160c22bf;
    padding: 10px;
    border-radius: 14px;
    justify-content: space-between;
    box-shadow: 0 7px 25px rgba(0,0,0,0.08);
    border: 1px solid #E5DFD8;
    white-space: nowrap;
}

.cardBox .card .numbers {
    font-weight: 450;
    font-size: 22px;
    margin-left: 44px;
}

.cardBox .card .cardName {
    font-size: 14px;
    margin-left: 44px;
}

.cardBox .card .iconBx {
    position: absolute;
    margin-top: 15px;
    margin-left: 5px;
}

.bottom {
    position: relative;
    width: 100%;
    padding: 10px;
    display: grid;
    grid-template-columns: 2.5fr 1fr;
    gap: 30px;
    margin-top: 10px;
}

.bottom .left {
    position: relative;
}

.etherstones-ctr {
    background: #282537;
    background-image: -webkit-radial-gradient(top, circle cover, #3c3b52 0%, #252233 80%);
    background-image: -moz-radial-gradient(top, circle cover, #3c3b52 0%, #252233 80%);
    background-image: -o-radial-gradient(top, circle cover, #3c3b52 0%, #252233 80%);
    background-image: radial-gradient(top, circle cover, #3c3b52 0%, #252233 80%);
    padding: 20px;
    box-shadow: 0 7px 25px rgba(0,0,0,0.08);
    border-radius: 20px;
    min-height: 550px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 15px;
    row-gap: 20px;
    justify-items: center;
}

.right {
    position: relative;
    display: grid;
    min-height: 500px;
    max-height: 500px;
}

.bottom .left .title {
    font-family: 'Gotham', sans-serif;
    font-weight: 500;
    font-size: 30px;
    white-space: nowrap;
    display: inline;
}

Button {
    background-color: #E5DFD8 !important;
}

Button:hover {
    background-color: #ffffff !important;
}

.etherstone-card-preview-position {
    position: absolute;
    margin-right: 100px;
    margin-top: -610px;
}

/* responsive */
@media (max-width: 2000px) {
    .cardBox {
        grid-template-columns: repeat(3, 1fr);
    }
    .bottom {
        grid-template-columns: repeat(1, 1fr);
    }
    .etherstones-ctr {
        grid-template-columns: repeat(5, 1fr);
    }
}

@media (max-width: 1885px) {
    .etherstones-ctr {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 1565px) {
    .etherstones-ctr {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 1175px) {
    .etherstones-ctr {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 761px) {
    .top-of-page {
        margin-top: 15px;
    }

    .bottom .left .title {
        text-align: center;
    }
    .cardBox {
        grid-template-columns: repeat(1, 1fr);
        gap: 0;
    }
    .bottom {
        grid-template-columns: repeat(1, 1fr);
    }
    .left-header {
        text-align: center;
    }
    .etherstones-ctr {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (max-width: 500px) {
    #bg {
        background-color: black !important;
        width: 500px;
    }

    .content {
        margin-left: 10px;
        margin-right: 10px;
    }

    .bottom .left .title {
        text-align: center;
    }
    .cardBox {
        grid-template-columns: repeat(1, 1fr);
        gap: 0;
    }
    .bottom {
        grid-template-columns: repeat(1, 1fr);
    }
    .left-header {
        text-align: center;
    }
    .etherstones-ctr {
        grid-template-columns: repeat(1, 1fr);
    }
}