.footer-dark {
    padding:50px 0;
    color:#f0f9ff;
    background-color:rgba(0,0,0,0.5);
    margin-top: 65px;
    overflow: hidden;
    position: absolute;
    overflow: auto;
    bottom: 0px;
    width: 100%;
}

.footer-container {
    margin-left: 20%;
    margin-right: 20%;
    height: 180px;
    overflow-y: none;
    margin-top: -20px;
}

.footer-grid {
    grid-template-columns: 1.2fr 1fr 1fr 1fr;
    display: grid;
}

.footer-description {
    margin-left: -40px;
    white-space: nowrap;
}

.footer-links-columns {
    margin-left: 25%;
    margin-top: 5%;
    white-space: nowrap;
    max-height: 180px;
    min-height: 180px;
    min-width: 180px;
    max-width: 180px;
}

.footer-dark ul {
    padding:0;
    list-style: none;
    line-height: 1.6;
    font-size: 16px;
    margin-bottom: 0;
}

.footer-dark ul a {
    color:inherit;
    text-decoration:none;
    opacity:0.6;
}

.footer-dark ul a:hover {
    opacity:0.8;
}

.footer-dark .item.text {
    margin-bottom:36px;
}

.footer-dark .item.text p {
    opacity:0.6;
    margin-bottom:0;
}

.footer-dark .item.social {
    text-align:center;
}

.footer-dark .item.social > a {
    font-size:20px;
    width:36px;
    height:36px;
    line-height:36px;
    display:inline-block;
    text-align:center;
    border-radius:50%;
    box-shadow:0 0 0 1px rgba(255,255,255,0.4);
    margin:0 8px;
    color:#fff;
    opacity:0.75;
}

.copyright {
    font-size: 1em !important;
}


@media (max-width:991px) {
    .footer-dark .item.social {
        text-align:center;
        margin-top:20px;
    }
}

@media (max-width:767px) {
    .footer-dark .item:not(.social) {
        text-align:center;
        padding-bottom:20px;
    }

    .footer-dark .item.text {
        margin-bottom:0;
    }
}
