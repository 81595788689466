@import url('../../fonts.css');

.nav {
    font-family: "Gotham", sans-serif;
}

@-webkit-keyframes outerRotate1 {
    0% {
        transform: translate(-50%, -50%) rotate(0);
   }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
   }
}
@-moz-keyframes outerRotate1 {
    0% {
        transform: translate(-50%, -50%) rotate(0);
   }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
   }
}
@-o-keyframes outerRotate1 {
    0% {
        transform: translate(-50%, -50%) rotate(0);
   }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
   }
}
@keyframes outerRotate1 {
    0% {
        transform: translate(-50%, -50%) rotate(0);
   }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
   }
}
@-webkit-keyframes outerRotate2 {
    0% {
        transform: translate(-50%, -50%) rotate(0);
   }
    100% {
        transform: translate(-50%, -50%) rotate(-360deg);
   }
}
@-moz-keyframes outerRotate2 {
    0% {
        transform: translate(-50%, -50%) rotate(0);
   }
    100% {
        transform: translate(-50%, -50%) rotate(-360deg);
   }
}
@-o-keyframes outerRotate2 {
    0% {
        transform: translate(-50%, -50%) rotate(0);
   }
    100% {
        transform: translate(-50%, -50%) rotate(-360deg);
   }
}
@keyframes outerRotate2 {
    0% {
        transform: translate(-50%, -50%) rotate(0);
   }
    100% {
        transform: translate(-50%, -50%) rotate(-360deg);
   }
}
@-webkit-keyframes textColour {
    0% {
        color: #fff;
   }
    100% {
        color: #3bb2d0;
   }
}
@-moz-keyframes textColour {
    0% {
        color: #fff;
   }
    100% {
        color: #3bb2d0;
   }
}
@-o-keyframes textColour {
    0% {
        color: #fff;
   }
    100% {
        color: #3bb2d0;
   }
}
@keyframes textColour {
    0% {
        color: #fff;
   }
    100% {
        color: #3bb2d0;
   }
}

body {
    margin: 0;
    padding: 0;
    background: #1a1a1a;
}
.e-loadholder {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-51%, -50%);
    -moz-transform: translate(-51%, -50%);
    -ms-transform: translate(-51%, -50%);
    -o-transform: translate(-51%, -50%);
    transform: translate(-51%, -50%);
    width: 240px;
    height: 240px;
    border: 5px solid #1b5f70;
    border-radius: 120px;
    box-sizing: border-box;
}
.e-loadholder:after {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-51%, -50%);
    -moz-transform: translate(-51%, -50%);
    -ms-transform: translate(-51%, -50%);
    -o-transform: translate(-51%, -50%);
    transform: translate(-51%, -50%);
    content: " ";
    display: block;
    background: #1a1a1a;
    transform-origin: center;
    z-index: 0;
}
.e-loadholder:after {
    width: 100px;
    height: 110%;
    -webkit-animation: outerRotate2 30s infinite linear;
    -moz-animation: outerRotate2 30s infinite linear;
    -o-animation: outerRotate2 30s infinite linear;
    animation: outerRotate2 30s infinite linear;
}
.e-loadholder .m-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-51%, -50%);
    -moz-transform: translate(-51%, -50%);
    -ms-transform: translate(-51%, -50%);
    -o-transform: translate(-51%, -50%);
    transform: translate(-51%, -50%);
    width: 200px;
    height: 200px;
    color: #888;
    text-align: center;
    border: 5px solid #2a93ae;
    border-radius: 100px;
    box-sizing: border-box;
    z-index: 20;
    text-transform: uppercase;
}
.e-loadholder .m-loader:after {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-51%, -50%);
    -moz-transform: translate(-51%, -50%);
    -ms-transform: translate(-51%, -50%);
    -o-transform: translate(-51%, -50%);
    transform: translate(-51%, -50%);
    content: " ";
    display: block;
    background: #1a1a1a;
    transform-origin: center;
    z-index: -1;
}
.e-loadholder .m-loader:after {
    width: 100px;
    height: 107%;
    -webkit-animation: outerRotate1 15s infinite linear;
    -moz-animation: outerRotate1 15s infinite linear;
    -o-animation: outerRotate1 15s infinite linear;
    animation: outerRotate1 15s infinite linear;
}
.e-loadholder .m-loader .e-text {
    font-size: 13px;
    font-size: 1.3rem;
    line-height: 130px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-51%, -50%);
    -moz-transform: translate(-51%, -50%);
    -ms-transform: translate(-51%, -50%);
    -o-transform: translate(-51%, -50%);
    transform: translate(-51%, -50%);
    -webkit-animation: textColour 1s alternate linear infinite;
    -moz-animation: textColour 1s alternate linear infinite;
    -o-animation: textColour 1s alternate linear infinite;
    animation: textColour 1s alternate linear infinite;
    display: block;
    width: 140px;
    height: 140px;
    text-align: center;
    border: 5px solid #3bb2d0;
    border-radius: 70px;
    box-sizing: border-box;
    z-index: 20;
}
.e-loadholder .m-loader .e-text:before, .e-loadholder .m-loader .e-text:after {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-51%, -50%);
    -moz-transform: translate(-51%, -50%);
    -ms-transform: translate(-51%, -50%);
    -o-transform: translate(-51%, -50%);
    transform: translate(-51%, -50%);
    content: " ";
    display: block;
    background: #1a1a1a;
    transform-origin: center;
    z-index: -1;
}
.e-loadholder .m-loader .e-text:before {
    width: 110%;
    height: 40px;
    -webkit-animation: outerRotate2 3.5s infinite linear;
    -moz-animation: outerRotate2 3.5s infinite linear;
    -o-animation: outerRotate2 3.5s infinite linear;
    animation: outerRotate2 3.5s infinite linear;
}
.e-loadholder .m-loader .e-text:after {
    width: 40px;
    height: 110%;
    -webkit-animation: outerRotate1 8s infinite linear;
    -moz-animation: outerRotate1 8s infinite linear;
    -o-animation: outerRotate1 8s infinite linear;
    animation: outerRotate1 8s infinite linear;
}

@media (max-width: 500px) {
    .e-loadholder .m-loader .e-text:before, .e-loadholder .m-loader .e-text:after {
        transition: 0.1s;
    }
    .e-loadholder:after {
        transition: 0.1s;
    }
    .e-loadholder .m-loader:after {
        transition: 0.1s;
    }
}
