@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');

.etherstone-ctrS {
    --card-width: 245px;
    --card-height: 357px;
    width: var(--card-width);
    height: var(--card-height);
    border-radius: 10.5px;
    transition: all 0.5s ease-in-out;
    font-family: 'Josefin Sans', sans-serif;
    background: url("../../../assets/holographic-outline.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

/* GREEN HOVER */
.hover-color-emeraldS {
    border-radius: 10.5px;
    transition: all 0.5s ease-in;
    height: var(--card-height);
}
.hover-color-emeraldS:hover {
    transition: all 0.5s ease-out;
    box-shadow: 0px 0px 4.2px 4.2px rgba(71, 170, 96, 0.75);
}

/* BLUE HOVER */
.hover-color-sapphireS {
    border-radius: 10.5px;
    transition: all 0.5s ease-in;
    height: var(--card-height);
}
.hover-color-sapphireS:hover {
    transition: all 0.5s ease-out;
    box-shadow: 0px 0px 4.2px 4.2px rgba(1, 101, 214, 0.75);
}

/* PURPLE HOVER */
.hover-color-amethystS {
    border-radius: 10.5px;
    transition: all 0.5s ease-in;
    height: var(--card-height);
}
.hover-color-amethystS:hover {
    transition: all 0.5s ease-out;
    box-shadow: 0px 0px 4.2px 4.2px rgba(217, 115, 248, 0.75);
}

/* RED HOVER */
.hover-color-rubyS {
    border-radius: 10.5px;
    transition: all 0.5s ease-in;
    height: var(--card-height);
}
.hover-color-rubyS:hover {
    transition: all 0.5s ease-out;
    box-shadow: 0px 0px 4.2px 4.2px rgba(165, 27, 27, 0.75);
}

/* GOLD HOVER */
.hover-color-radiantS {
    border-radius: 10.5px;
    transition: all 0.5s ease-in;
    height: var(--card-height);
}
.hover-color-radiantS:hover {
    transition: all 0.5s ease-out;
    box-shadow: 0px 0px 4.2px 4.2px rgba(217, 172, 55, 0.75);
}

.overlay-bgS {
    margin: 3.5px;
    width: calc(var(--card-width) - 7px);
    height: calc(var(--card-height) - 7px);
    background: url("../../../assets/etherstone-background.jpg");
    background-size: cover;
    background-position: center;
    z-index: 1;
    border-radius: 7px;
    position: absolute;
}

.overlayS {
    position: relative;
    width: 245px;
    height: 336px;

    --border-style: 3px solid #e4e3e3;
    --border-space: 8px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 3.5px 10.5px;
}

.overlay-elementS {
    position: absolute;
    width: 10.5px; /* controls the length of the borders */
    height: 10.5px;  /* controls the height of the borders */
    z-index: 2;
}

.overlayS .top-leftS {
    border-left: var(--border-style);
    border-top: var(--border-style);
    top: var(--border-space);
    left: var(--border-space);
}

.overlayS .top-rightS {
    border-right: var(--border-style);
    border-top: var(--border-style);
    top: var(--border-space);
    right: var(--border-space);
}

.overlayS .bottom-leftS {
    border-left: var(--border-style);
    border-bottom: var(--border-style);
    bottom: var(--border-space);
    left: var(--border-space);
}

.overlayS .bottom-rightS {
    border-right: var(--border-style);
    border-bottom: var(--border-style);
    bottom: var(--border-space);
    right: var(--border-space);
}

.etherstone-card-titleS {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    position: absolute;
    margin-top: 1.5px;
    font-size: 11.2px;
    font-weight: 700;
}

.etherstone-card-footerS {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    position: absolute;
    bottom: 1.4px;
    font-size: 8.4px;
    white-space: nowrap;
}

.etherstone-vid-ctrS {
    position: absolute;
    width: 210px;
    height: 210px;
    margin-left: 17.5px;
    margin-top: 21px;
}

video {
    position: absolute;
    z-index: 5;
    border: 1.4px solid #fff;
    border-radius: 21px;
    overflow: hidden;
}

.small-video {
    min-width: 210px;
    min-height: 210px;
}

.dotS {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    height: 4.9px;
    width: 4.9px;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    display: inline-block;
    margin-top: 3.5px;
  }

.locked-ethsS {
    left: 14px;
    white-space: nowrap;
    margin-top: 238px;
    font-size: 11.55px;
    font-weight: 500;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.665);
    border-radius: 9px;
    border: 1.4px solid black;
    line-height: 14.7px;
    padding: 3.5px;
    height: 42px;
}

.daily-compound-rewardS {
    left: 119px;
    white-space: nowrap;
    margin-top: 238px;
    font-size: 11.55px;
    font-weight: 500;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.665);
    border-radius: 9px;
    border: 1.4px solid black;
    line-height: 14.7px;
    padding: 3.5px;
    height: 42px;
    width: 105px;
}

.stats-description-leftS {
    font-weight: 300;
    line-height: 21px;
    position: absolute;
}

.stats-description-rightS {
    font-weight: 300;
    line-height: 21px;
    position: absolute;
}

.etherstone-button-ctrS {
    position: absolute;
    left: 17.5px;
    margin-top: 308px;
    white-space: nowrap;
    z-index: 3;
}

.claim-etherstone-btnS {
    padding: 4.9px;
    border-radius: 3.5px;
    background-color: #4a2161 !important;
    position: absolute;
    color: #af79c7;
    font-weight: 600;
    font-size: 10.5px;
    min-width: 87.5px;
}

.claim-etherstone-btnS:hover {
    background-color: #742d70 !important;
}

.compound-etherstone-btnS {
    padding: 4.9px;
    border-radius: 3.5px;
    background-color: #af79c7 !important;
    position: absolute;
    color: #4a2161;
    font-weight: 600;
    font-size: 10.5px;
    margin-left: 94.5px;
    white-space: nowrap;
    min-width: 115.5px;
}

.compound-etherstone-btnS:hover {
    background-color: #d197ce !important;
}

.pending-rewardS {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    margin-top: 287px;
    z-index: 5;
    font-weight: 800;
    white-space: nowrap;
    font-size: 11.2px;
}