@import url('../../fonts.css');

*{padding:0; margin:0; font-family: "Gotham", sans-serif; font-weight: 400;}

#closed-render-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    z-index: 100;
    color: white;
    margin-top: 50vh;
    display: none;
}

.apr-landing {
    animation: slideUp 2s forwards;
    line-height: 65px;
}

.apr-text {
    color: white;
    margin-top: -180px;
    font-size: 40px;
    font-weight: 800;
}

.scroll-svg:hover {
    cursor: pointer;
}

.second-page {
    background: linear-gradient(0deg, rgb(38, 8, 41), #000);
    background-attachment: fixed;
    position: absolute;
    margin-top: 100vh;
    width: 100vw;
    height: 260vh;
    min-height: 3200px;
}

.second-page-content {
    width: 100%;
}

.second-page-content-inner {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    width: 1100px;
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
    row-gap: 25px;
}

.second-page-content-left {
    /* background-color: blue; */
    font-size: 18px;
    color: #dcdce8
}

.second-page-content-right {
    margin-top: -25px;
    margin-right: -30px;
}

.second-page-bottom-grid {
    margin-top: 60px;
    width: 1100px;
    height: 300px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-left: auto;
    margin-right: auto;
    column-gap: 25px;
    row-gap: 25px;
}

.second-page-buttons {
    min-width: 150px !important;
    background-color: #755196 !important;
    border-radius: 20px !important;
    margin-top: 3px;
    color: #dcdce8 !important;
}

.second-page-buttons:hover {
    background-color: #a474d1 !important;
}

.third-page {
    width: 100%;
}

.third-page-inner {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 1100px;
    height: 600px;
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
    row-gap: 25px;;
}

.third-page-top-left {
    height: 100px;
}

.third-page-bottom-left {
    margin-top: 50px;
    background-image: none;
}

.third-page-bottom-right {
    margin-top: 15px;
}

.etherstones-small-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.landing-eth-container {
    padding: 20px;
    border-radius: 20px;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
}

.landing-square {
    width: 6rem;
    height: 6rem;
    background: #3C3C3D;
    transform-style: preserve-3d;
}
.landing-triangle {
    position: absolute;
    width: 8rem;
    height: 8rem;
}
.landing-triangle:nth-child(1) {
    width: 6rem;
    top: -33%;
    background: 	#C99D66;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    transform-origin: 50% 100%;
    transform: rotateX(-68deg);
}
.landing-triangle:nth-child(2) {
    width: 6rem;
    background: 	#C99D66;
    clip-path: polygon(50% 100%, 0 0, 100% 0);
    transform-origin: 50% 0%;
    transform: rotateX(68deg);
}
.landing-triangle:nth-child(3) {
    height: 6rem;
    left: -33%;
    background: #6d5537;
    transform-origin: 100% 50%;
    clip-path: polygon(100% 100%, 0 50%, 100% 0);
    transform: rotateY(68deg);
}
.landing-triangle:nth-child(4) {
    height: 6rem;
    background: #6d5537;
    transform-origin: 0% 50%;
    clip-path: polygon(0 100%, 100% 50%, 0 0);
    transform: rotateY(-68deg);
}
.pyramid {
    position: absolute;
    perspective: 500px;
    transform-style: preserve-3d;
}
.pyramid.inverse {
    transform: translateZ(-1.1428571429rem) rotateY(180deg);
}

.final-page {
    margin-top: 170px;
}

.final-page-content {
    width: 800px;
}

@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@keyframes slideUp {
    0% {
        transform: translateY(0);
        opacity:0;
    }
    100% {
        transform: translateY(-40px);opacity:1;
        opacity:1;
    }
}

/* responsive */
@media (max-width: 1100px) {

    .second-page-content-inner {
        grid-template-columns: 1fr;
        width: 700px;
    }

    .second-page { 
        height: 150vh;
        min-height: 4500px;
    }

    .second-page-content-right {
        margin-top: 0px;
        margin-right: 0px;
    }

    .second-page-bottom-grid {
        width: 700px;
        grid-template-columns: repeat(2, 1fr);
    }

    .second-page-bottom-1 {
        text-align: center;
    }
    .second-page-bottom-2 {
        text-align: center;
    }
    .second-page-bottom-3 {
        text-align: center;
    }

    .third-page {
        margin-top: 350px;
    }
    .third-page-inner {
        grid-template-columns: repeat(1, 1fr);
        place-items: center;
        width: 100%;
    }

    .etherstones-small-container {
        column-gap: 60px;
    }
    .third-page-top-right {
        height: 500px;
        margin-top: 300px;
    }

    .landing-eth-container {
        margin-top: 50px;
    }

    .create-etherstone-info {
        width: 400px;
    }

    .vault-details {
        width: 700px;
        text-align: center;
    }

    .stake-vault-header {
        margin-top: 40px;
    }

    .final-page-content {
        margin-top: 600px;
        width: 700px;
    }
    
}

@media (max-width: 900px) {
    .second-page {
        margin-top: 80vh;
    }

    .apr-text {
        margin-top: -500px;
    }
}

@media (max-width: 750px) {
    .second-page-content-inner {
        width: 100%;
        padding-left: 40px;
        padding-right: 40px;
    }

    .second-page-content-left {
        padding-left: 40px;
        padding-right: 40px;
        text-align: center;
    }
    
    .second-page-content-left-header {
        font-size: 22px !important;
    }

    .second-page { 
        height: 250vh;
        min-height: 5900px;
    }

    .second-page-content-right {
        margin-top: 0px;
        margin-right: 0px;
        padding-left: 40px;
        padding-right: 40px;
    }


    .second-page-bottom-1 {
        padding-left: 40px;
        padding-right: 40px;
    }
    .second-page-bottom-2 {
        padding-left: 40px;
        padding-right: 40px;
    }
    .second-page-bottom-3 {
        padding-left: 40px;
        padding-right: 40px;
    }


    .second-page-bottom-grid {
        width: 100%;
        grid-template-columns: repeat(1, 1fr);
        padding-left: 40px;
        padding-right: 40px;
    }

    .third-page {
        margin-top: 65vh;
        width: 100%;
        padding-left: 40px;
        padding-right: 40px;
    }
    .third-page-inner {
        grid-template-columns: repeat(1, 1fr);
        place-items: center;
        width: 100%;
    }

    .how-it-works-desc {
        padding-left: 40px;
        padding-right: 40px;
    }

    .etherstones-small-container {
        row-gap: 30px;
        grid-template-columns: 1fr;
    }

    .third-page-top-right {
        height: 400px;
        margin-top: 250px;
        padding-left: 40px;
        padding-right: 40px;
    }

    .landing-eth-container {
        margin-top: 550px;
        padding-left: 40px;
        padding-right: 40px;
    }

    /* .create-etherstone-header {
        position: absolute;
    } */

    .create-etherstone-info {
        width: 100%;
    }

    .vault-details {
        width: 100%;
        margin: 0 !important;
        padding-left: 40px;
        padding-right: 40px;
    }

    .stake-vault-header {
        margin-top: 50px;
        padding-left: 40px;
        padding-right: 40px;
    }

    .final-page-content {
        margin-top: 1000px;
        width: 100%;
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media(max-width: 470px) {
    
    .what-is-etherstones {
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
    }

    .second-page-content-inner {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }

    .second-page-content-left {
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
    }
    
    .second-page-content-left-header {
        font-size: 22px !important;
    }

    .second-page { 
        height: 250vh;
        min-height: 6050px;
    }

    .second-page-content-right {
        margin-top: 0px;
        margin-right: 0px;
        padding-left: 10px;
        padding-right: 10px;
    }


    .second-page-bottom-1 {
        padding-left: 10px;
        padding-right: 10px;
    }
    .second-page-bottom-2 {
        padding-left: 10px;
        padding-right: 10px;
    }
    .second-page-bottom-3 {
        padding-left: 10px;
        padding-right: 10px;
    }


    .second-page-bottom-grid {
        width: 100%;
        grid-template-columns: repeat(1, 1fr);
        padding-left: 10px;
        padding-right: 10px;
    }

    .third-page {
        margin-top: 82vh;
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }
    .third-page-inner {
        grid-template-columns: repeat(1, 1fr);
        place-items: center;
        width: 100%;
    }

    .how-it-works-desc {
        padding-left: 10px;
        padding-right: 10px;
    }

    .etherstones-small-container {
        row-gap: 30px;
        grid-template-columns: 1fr;
    }

    .third-page-top-right {
        height: 400px;
        margin-top: 450px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .landing-eth-container {
        margin-top: 550px;
        padding-left: 10px;
        padding-right: 10px;
    }

    /* .create-etherstone-header {
        position: absolute;
    } */

    .create-etherstone-info {
        width: 100%;
        margin-left: -20px !important; 
    }

    .vault-details {
        width: 100%;
        margin: 0 !important;
        padding-left: 10px;
        padding-right: 10px;
    }

    .stake-vault-header {
        margin-top: 50px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .final-page-content {
        margin-top: 1300px;
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }

    #etherstone-card-genesis {
        display: none;
    }
}