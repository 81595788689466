@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 400;
    src:url('./ff/Gotham-Book.woff2') format('woff2'),
        url('./ff/Gotham-Book.ttf') format('truetype');
}
@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 300;
    src:
    url('./ff/Gotham-Light.woff2') format('woff2');
}

@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 500;
    src:
    url('./ff/Gotham-Medium.woff2') format('woff2');
}

@font-face {
    font-family: 'Gotham';
    font-style: italic;
    font-weight: 500;
    src:
    url('https://fonts.cdnfonts.com/s/14898/GothamMediumItalic.woff2') format('woff2'),
    url('https://fonts.cdnfonts.com/s/14898/GothamMediumItalic.ttf') format('truetype');
}
@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    src:
    url('https://fonts.cdnfonts.com/s/14898/GothamBold.woff2') format('woff2'),
    url('https://fonts.cdnfonts.com/s/14898/GothamBold.ttf') format('truetype');
}
@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    src:
    url('https://fonts.cdnfonts.com/s/14898/Gotham-Bold.woff2') format('woff2'),
    url('https://fonts.cdnfonts.com/s/14898/Gotham-Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'Gotham';
    font-style: italic;
    font-weight: 700;
    src: url('https://fonts.cdnfonts.com/s/14898/GothamBoldItalic.woff') format('woff'),
    url('https://fonts.cdnfonts.com/s/14898/GothamBoldItalic.woff2') format('woff2'),
    url('https://fonts.cdnfonts.com/s/14898/GothamBoldItalic.ttf') format('truetype');
}