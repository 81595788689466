#presale-bg {
    background-image: url("../assets/yellow_background.png");
    width: 100vw;
    height: 100vh;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    object-fit: cover;
    font-family: 'Gotham', sans-serif;
    color: white;
    position: absolute;
    position: fixed;
    overflow-y: auto;
}

.presale-center {
    width: 50%;
    margin: 0 auto;
    width: 1200px;
    height: 900px;
    border-radius: 15px;
    margin-bottom: 100px;
}

.presale-header {
    margin-top: 100px;
    width: 100%;
    font-weight: 500;
    font-size: 40px;
    text-align: center;
}

.presale-header-desc {
    width: 100%;
    text-align: center;
    font-size: 18px;
}

.presale-details {
    margin-top: 100px;
    width: 900px;
    margin-left: 150px;
    background: rgba(0,0,0,0.8);
    height: 600px;
    border-radius: 30px;
    padding: 10px;
    box-shadow: rgba(206, 2, 172, 0.993) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
}

.presale-details-text {
    margin-top: 15px;
    margin-left: 30px;
    margin-right: 30px;
}

.presale-options {
    margin-top: 20px;
    font-weight: 500;
    font-size: 18px;
}

.presale-button-grid {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.presale-button {
    color: black !important;
    margin: 5px;
    background-color: "#af79c7" !important;
}

.presale-button:hover {
    background-color: "#d197ce" !important;
}

@media (max-width: 1000px) {

    .presale-header-buttons {
        position: absolute;
        display: inline-block;
        margin-left: 200px;
    }

    #presale-header-button-1 {
        position: absolute;
        min-width: 153.16px;
    }

    #presale-header-button-2 {
        position: absolute;
        min-width: 153.16px;
    }

    #presale-header-button-3 {
        position: absolute;
        min-width: 153.16px;
        margin-left: 163.16px;
    }
}