/* @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap'); */

.etherstone-ctr {
    --card-width: 350px;
    --card-height: 510px;
    width: var(--card-width);
    height: var(--card-height);
    border-radius: 15px;
    transition: all 0.5s ease-in-out;
    font-family: 'Gotham', sans-serif;
    background: url("../../assets/holographic-outline.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

/* GREEN HOVER */
.hover-color-emerald {
    border-radius: 15px;
    transition: all 0.5s ease-in;
    height: var(--card-height);
}
.hover-color-emerald:hover {
    transition: all 0.5s ease-out;
    box-shadow: 0px 0px 6px 6px rgba(71, 170, 96, 0.75);
}

/* BLUE HOVER */
.hover-color-sapphire {
    border-radius: 15px;
    transition: all 0.5s ease-in;
    height: var(--card-height);
}
.hover-color-sapphire:hover {
    transition: all 0.5s ease-out;
    box-shadow: 0px 0px 6px 6px rgba(1, 101, 214, 0.75);
}

/* PURPLE HOVER */
.hover-color-amethyst {
    border-radius: 15px;
    transition: all 0.5s ease-in;
    height: var(--card-height);
}
.hover-color-amethyst:hover {
    transition: all 0.5s ease-out;
    box-shadow: 0px 0px 6px 6px rgba(217, 115, 248, 0.75);
}

/* RED HOVER */
.hover-color-ruby {
    border-radius: 15px;
    transition: all 0.5s ease-in;
    height: var(--card-height);
}
.hover-color-ruby:hover {
    transition: all 0.5s ease-out;
    box-shadow: 0px 0px 6px 6px rgba(165, 27, 27, 0.75);
}

/* GOLD HOVER */
.hover-color-radiant {
    border-radius: 15px;
    transition: all 0.5s ease-in;
    height: var(--card-height);
}
.hover-color-radiant:hover {
    transition: all 0.5s ease-out;
    box-shadow: 0px 0px 6px 6px rgba(217, 172, 55, 0.75);
}

.overlay-bg {
    margin: 5px;
    width: calc(var(--card-width) - 10px);
    height: calc(var(--card-height) - 10px);
    background: url("../../assets/etherstone-background.jpg");
    background-size: cover;
    background-position: center;
    z-index: 1;
    border-radius: 10px;
    position: absolute;
}

.overlay {
    position: relative;
    width: 350px;
    height: 480px;

    --border-style: 3px solid #e4e3e3;
    --border-space: 8px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.overlay-element {
    position: absolute;
    width: 15px; /* controls the length of the borders */
    height: 15px;  /* controls the height of the borders */
    z-index: 2;
}

.overlay .top-left {
    border-left: var(--border-style);
    border-top: var(--border-style);
    top: var(--border-space);
    left: var(--border-space);
}

.overlay .top-right {
    border-right: var(--border-style);
    border-top: var(--border-style);
    top: var(--border-space);
    right: var(--border-space);
}

.overlay .bottom-left {
    border-left: var(--border-style);
    border-bottom: var(--border-style);
    bottom: var(--border-space);
    left: var(--border-space);
}

.overlay .bottom-right {
    border-right: var(--border-style);
    border-bottom: var(--border-style);
    bottom: var(--border-space);
    right: var(--border-space);
}

.etherstone-card-title {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    position: absolute;
    margin-top: 3px;
    font-size: 16px;
    font-weight: 700;
}

.etherstone-card-footer {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    position: absolute;
    bottom: 2px;
    font-size: 12px;
    white-space: nowrap;
}

.etherstone-vid-ctr {
    position: absolute;
    width: 300px;
    height: 300px;
    margin-left: 25px;
    margin-top: 40px;
}

video {
    position: absolute;
    z-index: 5;
    border: 2px solid #fff;
    border-radius: 40px;
    overflow: hidden;
}

.large-video {
    min-width: 300px;
    min-height: 300px;
}

.dot {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    height: 7px;
    width: 7px;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    display: inline-block;
    margin-top: 5px;
  }

.locked-eths {
    left: 20px;
    white-space: nowrap;
    margin-top: 340px;
    font-size: 16.5px;
    font-weight: 500;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.665);
    border-radius: 9px;
    border: 2px solid black;
    line-height: 21px;
    padding: 5px;
    height: 60px;
}

.daily-compound-reward {
    left: 170px;
    white-space: nowrap;
    margin-top: 340px;
    font-size: 16px;
    font-weight: 500;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.665);
    border-radius: 9px;
    border: 2px solid black;
    line-height: 21px;
    padding: 5px;
    height: 60px;
    width: 150px;
}

.stats-description-left {
    font-weight: 300;
    line-height: 30px;
    position: absolute;
}

.stats-description-right {
    font-weight: 300;
    line-height: 30px;
    font-size: 16px;
    position: absolute;
}

.etherstone-button-ctr {
    position: absolute;
    left: 25px;
    margin-top: 440px;
    white-space: nowrap;
    z-index: 3;
}

.claim-etherstone-btn {
    padding: 7px;
    border-radius: 5px;
    background-color: #4a2161 !important;
    position: absolute;
    color: #af79c7;
    font-weight: 600;
    font-size: 15px;
    min-width: 125px;
}

.claim-etherstone-btn:hover {
    background-color: #742d70 !important;
}

.compound-etherstone-btn {
    padding: 7px;
    border-radius: 5px;
    background-color: #af79c7 !important;
    position: absolute;
    color: #4a2161;
    font-weight: 600;
    font-size: 15px;
    margin-left: 135px;
    white-space: nowrap;
    min-width: 165px;
}

.compound-etherstone-btn:hover {
    background-color: #d197ce !important;
}

.pending-reward {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    margin-top: 410px;
    z-index: 5;
    font-weight: 800;
    white-space: nowrap;
}

.cooldown-button {
    padding: 7px;
    border-radius: 5px;
    background-color: #959595 !important;
    position: absolute;
    color: black;
    font-weight: 600;
    font-size: 15px;
    min-width: 125px;
    cursor: not-allowed;
}

.cooldown-button:hover {
    background-color: #959595 !important;
}

.cooldown-button-right {
    padding: 7px;
    border-radius: 5px;
    background-color: #959595 !important;
    position: absolute;
    color: black;
    font-weight: 600;
    font-size: 15px;
    min-width: 165px;
    cursor: not-allowed;
}

.cooldown-button-right:hover {
    background-color: #959595 !important;
}

.smallest-font-size {
    font-size: 15px !important;
}

.reduced-font-size {
    font-size: 15.5px !important;
}